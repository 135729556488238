import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  usuario:any={};
  coneccionImpresora:string;
  impresoraSeleccionada:any={};
  incluirInventario:boolean=true;
  stockMinimo:number=2;
  facturacion:boolean=true;
  constructor(private toastrService: NbToastrService) {
    this.getIncluirInventario();
   }

  setUsuario(data){
    this.usuario=data;
  }

  getUsuario(){
    return this.usuario;
  }

  setImpresoraSeleccionada(impresora:string){
    this.impresoraSeleccionada={nombre:impresora}
     }

  getImpresoraSeleccionada(){
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      this.impresoraSeleccionada = conf.impresora?.nombre;
      return this.impresoraSeleccionada;
    }
    return undefined;
  }

  getFacturacion():Promise<boolean>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.facturacion?.activo ?? undefined;
      if(temp=="true" || temp==undefined){
        this.facturacion=true;
      }else{
        this.facturacion=false;
      }
      console.log(this.facturacion);
    }
    resolve(this.facturacion);
  });
  }

  getIncluirInventario():Promise<boolean>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.inventario?.incluirInventario ?? undefined;
      if(temp=="true" || temp==undefined){
        this.incluirInventario=true;
      }else{
        this.incluirInventario=false;
      }
      console.log(this.incluirInventario);
    }
    resolve(this.incluirInventario);
  });
  }

  getTipodeComunicacionconImpresora():Promise<string>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.impresora?.conexion ?? undefined;
      if(temp==undefined){
        this.coneccionImpresora ="USB";
      }else{
        this.coneccionImpresora=temp;
      }
    }else{
      this.coneccionImpresora ="USB";
    }
    resolve(this.coneccionImpresora);
  });
  }
  

  setIncluirInventario(incluir:boolean){
    this.incluirInventario=incluir;
  }

  setFacturacion(incluir:boolean){
    this.facturacion=incluir;
  }


  setStockMinimo(stockMinimo){
    this.stockMinimo=stockMinimo;
    let data = JSON.parse(localStorage.getItem("CONF"));
    if(data != null){
    data.inventario={stockMinimo:stockMinimo};
    }else{
      data = {inventario:{stockMinimo:stockMinimo}};
    }
    localStorage.setItem("CONF", JSON.stringify(data));
    // this.showToast('info','Informacion',`Stock minimo actualizado!`);
  }

  getStockMinimo():Promise<number>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.inventario?.stockMinimo ?? undefined;
      if(temp==undefined){
        this.stockMinimo=2;
      }else{
        this.stockMinimo=Number(temp);
      }
    }
    resolve(this.stockMinimo);
  });
  }
  

  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 5000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
  
    this.toastrService.show(
      body,
      `${title}`,
      config);
  }
}
